import React from 'react';
import { useState } from 'react';
import cn from 'classnames';

import { Sprite, SpriteName } from '../Sprite';
import T from '../Translate';

import { type InputProps, TextInput } from './Input';

import styles from './PasswordInput.module.scss';

const MIN_LENGTH = 8;

function isPasswordLong(password: string): boolean {
  return password.length >= MIN_LENGTH;
}

function hasPasswordUppercase(password: string): boolean {
  return /[A-Z]/.test(password);
}

function hasPasswordLowercase(password: string): boolean {
  return /[a-z]/.test(password);
}

function hasPasswordSpecial(password: string): boolean {
  return /[^a-z\s:]/i.test(password);
}

export function isPasswordStrong(password: string): boolean {
  return (
    isPasswordLong(password) &&
    hasPasswordUppercase(password) &&
    hasPasswordLowercase(password) &&
    hasPasswordSpecial(password)
  );
}

interface PasswordInputProps extends InputProps<string> {
  hideHints?: boolean;
  highlightHints?: boolean;
}

export function PasswordInput({ hideHints = false, highlightHints, ...props }: PasswordInputProps) {
  const [shown, setShown] = useState(false);
  const [showEye, setShowEye] = useState(false);

  return (
    <div className={cn(styles.passwordInput, props.className)}>
      <TextInput
        {...props}
        iconRight={
          <Sprite className={!showEye ? 'd-none' : null} name={SpriteName.EYE} onClick={() => setShown(!shown)} />
        }
        onBlur={() => setShowEye(props.value && props.value !== '')}
        onFocus={() => setShowEye(true)}
        type={shown ? 'text' : 'password'}
      />
      {!hideHints && (
        <div className={cn(styles.hints, { highlight: highlightHints })}>
          <Hint checked={isPasswordLong(props.value)}>
            <T data={{ password_min_length: MIN_LENGTH }} id="global.password.validation.length" />
          </Hint>
          <Hint checked={hasPasswordLowercase(props.value)}>
            <T id="global.password.validation.lowercase" />
          </Hint>
          <Hint checked={hasPasswordUppercase(props.value)}>
            <T id="global.password.validation.uppercase" />
          </Hint>
          <Hint checked={hasPasswordSpecial(props.value)}>
            <T id="global.password.validation.special" />
          </Hint>
        </div>
      )}
    </div>
  );
}

interface HintProps {
  checked: boolean;
  children: React.ReactNode;
}

function Hint({ checked, children }: HintProps) {
  return (
    <div className={cn(styles.hint, { checked })}>
      <i className={styles.icon} />
      {children}
    </div>
  );
}
