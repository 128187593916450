import React from 'react';
import { useRef, useState } from 'react';
import cn from 'classnames';

interface Props {
  animate?: boolean;
  className?: string;
  openedByDefault?: boolean;
  header(shown: boolean): React.ReactNode;
}

export default function CollapsibleCard(props: React.PropsWithChildren<Props>) {
  const [shown, setShown] = useState(props.openedByDefault ?? false);
  const ref = useRef<HTMLDivElement>(null);

  return (
    <div
      className={cn(
        'card collapsible',
        {
          show: shown && !props.animate,
          'collapsible-animation': props.animate,
        },
        props.className,
      )}
    >
      <div className="card-header cursor-pointer" onClick={() => setShown(!shown)}>
        {props.header(shown)}
      </div>
      <div
        className={cn('collapse')}
        style={props.animate ? { height: shown ? ref.current.clientHeight + 1 : 0 } : undefined}
      >
        <div className="card-body" ref={ref}>
          {props.children}
        </div>
      </div>
    </div>
  );
}
