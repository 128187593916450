import React from 'react';

import { useOnLoadTracking } from '../../hooks/useTracking';
import RouteEnum from '../../RouteEnum';
import Tracking from '../../services/Tracking';
import { StaticImage } from '../Image';
import Link from '../Link';
import MobileModal from '../MobileModal';
import T from '../Translate';

interface Props {
  email: string;
  onClose: () => void;
}

export function UserRegistrationExistingUserModal(props: Props) {
  useOnLoadTracking(() => Tracking.track('SIGNUP_EMAIL_EXISTS_VIEW'));
  return (
    <MobileModal blackBackdrop onClose={props.onClose} opened size="md">
      <div className="modal-body d-flex flex-column align-items-center">
        <T as="h2" className="h4" id="signup_account_exists_title" />
        <T as="p" className="sm" id="signup_account_exists_please_login" />
        <StaticImage name="user-registration-existing-user" />
        <div className="mb-4">{props.email}</div>

        <Link className="button button-primary button-block button-icon" to={RouteEnum.SIGN_IN}>
          <i className="icon icon-arrow-right-circle icon-right" />
          <T id="signup_account_exists_button_login" />
        </Link>
      </div>
    </MobileModal>
  );
}
