import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import cn from 'classnames';
import { string } from 'yup';

import { ModalEnum } from '../../ModalEnum';
import RouteEnum from '../../RouteEnum';
import { useRouteContext } from '../../RouteProvider';
import Tracking from '../../services/Tracking';
import { openModal } from '../../store/app';
import { flashMessageError, loginUserWithEmail } from '../../store/session';
import { TextInput } from '../Input/Input';
import { PasswordInput } from '../Input/PasswordInput';
import { SubmitButton } from '../Input/SubmitButton';
import { useField, useValidationSchema } from '../Input/useField';
import { useForm } from '../Input/useForm';
import PageLayout from '../Layout/Page';
import T from '../Translate';
import WizardNavigation from '../WizardNavigation';

import { UserRegistrationExistingUserModal } from './UserRegistrationExistingUserModal';

import styles from './SignIn.module.scss';

export default function SignIn() {
  const dispatch: DispatchFunction = useDispatch();
  const { location, navigate, generatePath } = useRouteContext();
  const [opened, setOpened] = useState(false);
  const emailValidation = useValidationSchema(string().email('global.email.invalid').required('global.required'));
  const [email, setEmail] = useField<string>('email', '', emailValidation);
  const [password, setPassword] = useField<string>('password', '', value => {
    if (!value || value === '') return <T id="global.required" />;
    return null;
  });

  const [handleSubmit, form] = useForm([email, password], async () => {
    try {
      const hasConsents = await dispatch(loginUserWithEmail(email.value, password.value));
      const redirect = new URLSearchParams(location.search).get('redirect') ?? RouteEnum.HOMEPAGE;
      navigate(generatePath(!hasConsents ? RouteEnum.USER_REGISTRATION : redirect));
    } catch (error) {
      dispatch(flashMessageError(error.message));
    }
  });

  const handleReturn = () => {
    const redirect = new URLSearchParams(location.search).get('redirect') ?? RouteEnum.HOMEPAGE;
    navigate(generatePath(redirect));
  };

  const handleResetPasswordClick = () => {
    Tracking.track('LOGIN_PASSWORD_RESET_CLICKED');
    dispatch(openModal(ModalEnum.PASSWORD_RESET));
  };

  return (
    <PageLayout hideFooter pageview="login_with_email">
      <form className="main-container container container--md" onSubmit={handleSubmit}>
        <WizardNavigation
          onReturnClick={handleReturn}
          supportModal={{
            noOfFaqs: 12,
            pageview: 'login_support',
            size: 'lg',
          }}
          translationPrefix="user.registration"
        />
        <div className={cn(styles.container, 'container container--sm')}>
          <T as="h2" className={styles.title} id="modals.signIn.title" />

          <TextInput
            autoComplete="email"
            error={form.errors.email}
            id="sign-in-email"
            label="modals.signIn.email.label"
            name="email"
            onChange={setEmail}
            required
            tabIndex={1}
            type="email"
            value={email.value}
          />

          <PasswordInput
            autoComplete="current-password"
            error={form.errors.password}
            hideHints
            id="sign-in-password"
            label="modals.signIn.password.label"
            name="password"
            onChange={setPassword}
            required
            tabIndex={1}
            type="password"
            value={password.value}
          />

          <div className={styles.bottom}>
            <button className={styles.bottomButton} onClick={handleResetPasswordClick} type="button">
              <T id="modals.signIn.forgottenPassword" />
            </button>
          </div>

          <SubmitButton block primary submitting={form.submitting}>
            <T id="modals.signIn.submit" />
          </SubmitButton>
        </div>
      </form>
      {opened ? <UserRegistrationExistingUserModal email={email.value} onClose={() => setOpened(false)} /> : null}
    </PageLayout>
  );
}
