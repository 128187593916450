import React from 'react';
import { useSelector } from 'react-redux';

import { mapAttributesToValueLabelPairs, sortByAlphabet } from '../../model/Enum';
import type { Country } from '../../services/Country';
import type { ReduxState } from '../../store';

import Select, { type SelectAttributes, SelectField } from './Select';

interface Props extends SelectAttributes<Country> {
  value: Country;
  onChange(country: Country): void;
}

export function CountrySelect(props: Props) {
  const countries = useSelector((state: ReduxState) => mapAttributesToValueLabelPairs(state.enums.country.data));

  return (
    <Select
      {...props}
      formatOptionLabel={option => (
        <>
          <i className={`flag flag-${option.value.toLowerCase()} mr-2`} />
          {option.label}
        </>
      )}
      options={sortByAlphabet(countries)}
      value={props.value}
    />
  );
}

export function RegionSelect(props: Props) {
  const regions = useSelector((state: ReduxState) => mapAttributesToValueLabelPairs(state.enums.regions.data));

  return (
    <Select
      {...props}
      formatOptionLabel={option => (
        <>
          <i className={`flag flag-${option.value.toLowerCase()} mr-2`} />
          {option.label}
        </>
      )}
      options={sortByAlphabet(regions)}
      value={props.value}
    />
  );
}

interface FieldProps extends SelectAttributes<Country> {
  name: string;
  disabled?: boolean;
}

export function CountryField(props: FieldProps) {
  const countries = useSelector((state: ReduxState) => mapAttributesToValueLabelPairs(state.enums.country.data));

  return (
    <SelectField
      {...props}
      formatOptionLabel={option => (
        <>
          <i className={`flag flag-${option.value.toLowerCase()} mr-2`} />
          {option.label}
        </>
      )}
      isDisabled={props.disabled}
      name={props.name}
      options={sortByAlphabet(countries)}
    />
  );
}

export function RegionField(props: FieldProps) {
  const regions = useSelector((state: ReduxState) => mapAttributesToValueLabelPairs(state.enums.regions.data));

  return (
    <SelectField
      {...props}
      formatOptionLabel={option => (
        <>
          <i className={`flag flag-${option.value.toLowerCase()} mr-2`} />
          {option.label}
        </>
      )}
      isDisabled={props.disabled}
      name={props.name}
      options={sortByAlphabet(regions)}
    />
  );
}
