import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cn from 'classnames';

import { range } from '../../helpers';
import { useLanguage } from '../../hooks/useLanguage';
import { type Country, languageToCountry } from '../../services/Country';
import type { Pageview } from '../../services/Pageview';
import type { ReduxState } from '../../store';
import CollapsibleCard from '../CollapsibleCard';
import { RegionSelect } from '../Form/CountrySelect';
import Modal from '../Modal';
import T from '../Translate';

export interface SupportModalProps {
  pageview: Pageview;
  size?: 'sm' | 'md' | 'lg' | 'xl';
  noOfFaqs: number;
}

interface Props extends SupportModalProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
}

export function SupportModal({ opened, setOpened, pageview, size = 'md', noOfFaqs }: Props) {
  const userRegion = useSelector((store: ReduxState) => store.user?.country?.alpha2_code);
  const language = useLanguage();
  const [region, setRegion] = useState<Country>(userRegion ?? languageToCountry(language));

  return (
    <Modal
      closeModal={() => setOpened(false)}
      hideScrollOverlay
      isOpen={opened}
      pageview={pageview}
      scrollable
      size={size}
    >
      <div className="modal-body">
        <T as="h3" className="mb-3" id="user.registration.support.title" />

        <T as="h4" className="mb-4" id="user.registration.support.contact.title" />

        <div className="form-group mb-3">
          <label htmlFor="country">
            <T id="user.registration.support.country.label" />
          </label>
          <RegionSelect
            id="country"
            onChange={setRegion}
            placeholder="user.registration.support.country.placeholder"
            value={region}
          />
        </div>
        {region !== null ? (
          <div className="mb-5">
            <div className="d-flex align-items-center justify-content-between mb-3">
              <T className="font-weight-bold" id="user.registration.support.contact.openingHours.label" />
              <T context={region.toLowerCase()} id="user.registration.support.contact.openingHours" multiline />
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <T className="font-weight-bold" id="user.registration.support.contact.assistance.label" />
              <T context={region.toLowerCase()} id="user.registration.support.contact.assistance" multiline />
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <T className="font-weight-bold" id="user.registration.support.contact.insuranceEvents.label" />
              <T context={region.toLowerCase()} id="user.registration.support.contact.insuranceEvents" multiline />
            </div>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <T className="font-weight-bold" id="user.registration.support.contact.phone.label" />
              <T context={region.toLowerCase()} id="user.registration.support.contact.phone" multiline />
            </div>
            <div className="d-flex align-items-center justify-content-between">
              <T className="font-weight-bold" id="user.registration.support.contact.email.label" />
              <T context={region.toLowerCase()} id="user.registration.support.contact.email" multiline />
            </div>
          </div>
        ) : null}

        <T as="h4" className="mb-4" id="support.faq.title" />
        {range(1, noOfFaqs).map(index => (
          <CollapsibleCard
            animate
            className={cn({ 'mt-3': index })}
            header={shown => (
              <div className="d-flex align-items-center justify-content-between">
                <T className="font-weight-bold" id={`support.faq.q.${index}`} />
                <i
                  className={cn('icon icon-arrow-down', {
                    'icon-rotate-180': shown,
                  })}
                />
              </div>
            )}
            key={`faq-${index}`}
          >
            <T as="p" className="card-text" id={`support.faq.a.${index}`} multiline />
          </CollapsibleCard>
        ))}
      </div>
    </Modal>
  );
}
